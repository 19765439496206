// eslint-disable-next-line import/prefer-default-export

export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            ensembleIds
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                order
                form {
                    id
                    name
                }
                ensembles {
                    items {
                        id
                        ensemble {
                            id
                            name
                        }
                    }
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                formInstrumentsId
            }
            nextToken
        }
    }
`;

export const listInstrumentsForChair = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                order
                form {
                    id
                    name
                }
                ensembles {
                    items {
                        id
                        ensemble {
                            id
                            name
                        }
                    }
                }
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                parts
                instruments {
                    items {
                        id
                        instrument {
                            name
                        }
                    }
                }
                hasParts
                hasAlternates
            }
            nextToken
        }
    }
`;
